<template>
    <div class="main-wrapper" v-loading.fullscreen.lock="loading">
        <div class="container-fluid login-wrapper">
            <div class="row">
                <div class="col-xl-7 col-lg-12 col-md-12 col-12 px-0 d-none d-lg-block">
                    <div class="sing_bg">
                        <div class="Mainlogo d-none d-lg-block">
                            <img src="/snapp-associates/brand_logo.png" class="img-fluid" alt="img">
                        </div>

                        <div class="sigin-left d-none d-lg-block">
                            <div class="login-content">
                                <h3>Sign Up Now </h3>
                                <p>Begin your restaurant and hospitality industry training today!</p>
                            </div>
                            <div class="signup-bttns">
                                <router-link to="/user_register">
                                    <div class="signup-box mr-4">
                                        <img src="/snapp-associates/single-user.png" class="indiviual-img" alt="Individual User">
                                        <p>Individual Sign Up</p>
                                    </div>
                                </router-link>
                                <router-link to="/register">
                                    <div class="signup-box">
                                        <img src="/snapp-associates/company-user.png" class="company-img" alt="Company User">
                                        <p>Company Sign Up</p>
                                    </div>
                                </router-link>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-xl-5 col-lg-12 col-md-12 col-12 px-0">
                    <div class="login-form-area">

                        <div class="login-form-section">
                            <div class="Mobile-logo d-lg-none mb-4 text-center">
                                <img src="/snapp-associates/logo.png" class="img-fluid" alt="img">
                            </div>
                            <div class="login-title text-center contentlogin fade-in">
                                <h1>Welcome Back!</h1>
                                <p>Please login with your user information below.</p>
                            </div>
                            <div class="login-form">
                                <div class="login-form-control contentlogin fade-in">
                                    <!--Form Control-->
                                    <form id="formlogin" class="slide-left">
                                        <div class="input-control mb-4">
                                            <div class="l-form-group">
                                                <label class="label-login pb-2">Username</label>
                                                <input class="form-control" type="text" placeholder="Enter your username" required v-model="form.username">
                                                <div class="input-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.132" height="12.849" viewBox="0 0 17.132 12.849">
                                                        <path id="Layer_2" data-name="Layer 2" d="M0,1.51V1.071A1.071,1.071,0,0,1,1.071,0h14.99a1.071,1.071,0,0,1,1.071,1.071V1.51L8.566,6.864ZM8.85,7.949a.536.536,0,0,1-.567,0L0,2.773v9.005a1.071,1.071,0,0,0,1.071,1.071h14.99a1.071,1.071,0,0,0,1.071-1.071V2.773Z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-control">
                                            <div class="l-form-group">
                                                <label class="label-login pb-2">Password</label>
                                                <input id="password-field" type="password" class="form-control" placeholder="Enter your password" required v-model="form.password">
                                                <div class="input-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
                                                        <path id="lock_5_" data-name="lock (5)" d="M17,9V7A5,5,0,0,0,7,7V9a2.946,2.946,0,0,0-3,3v7a2.946,2.946,0,0,0,3,3H17a2.946,2.946,0,0,0,3-3V12A2.946,2.946,0,0,0,17,9ZM9,7a3,3,0,0,1,6,0V9H9Zm4.1,8.5-.1.1V17a1,1,0,0,1-2,0V15.6a1.487,1.487,0,1,1,2.1-.1Z" transform="translate(-4 -2)"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="usefulfields">
                                            <div class="forgotPassword">
                                                <router-link to="/forget_password" class="text-end float-right">
                                                    Forgot Your Password?
                                                </router-link>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="btn log-btn w-100" @click.prevent="onSubmit">Sign In</button>
                                        </div>
                                        <div class="donthaveact d-lg-none">
                                            If you don't have account?
                                            <router-link to="/snapp-associates-signup" class="text-end float-right">
                                                Signup
                                            </router-link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
    name: "index",
    data() {
        return {
            loading: false,
            form: {
                username: "",
                password: "",
            },
            errors: {
                invalid: "",
            },
            siteName: "",
            year: new Date().getFullYear(),
        };
    },
    created: function () {
        if (this.$route.query.email && this.$route.query.password) {
            this.form.username = this.$route.query.email;
            this.form.password = this.$route.query.password;

            this.onSubmit();
        }
        this.siteName = 'ORLA';
    },
    methods: {
        onSubmit() {
            this.loading = true;
            this.$http
                .post("user/login", {
                    email: this.form.username,
                    password: this.form.password,
                })
                .then((resp) => {
                    localStorage.setItem("hot-token", resp.data.token);
                    localStorage.setItem("hot-user", resp.data.role);
                    localStorage.setItem("hot-logged-user", resp.data.user_id);
                    localStorage.setItem("hot-user-full-name", resp.data.full_name);
                    let headers = {
                        authorization: "Bearer " + resp.data.token,
                        "content-type": "application/json",
                    };
                    this.$http.defaults.headers.authorization =
                        "Bearer " + resp.data.token;
                    let admin = "";
                    let level = "";

                    switch (resp.data.role) {
                        case "super-admin":
                            admin = "super_admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    this.$router.push("/dashboard");
                                });

                            break;
                        case "company-admin":
                            admin = "admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/data",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    if (resp.data.level) {
                                        localStorage.setItem("hot-company-level", "parent");
                                    } else {
                                        localStorage.setItem("hot-company-level", "child");
                                    }
                                    localStorage.setItem("hot-admin-id", resp.data.admin_id);
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-company-name", resp.data[0].name);
                                    this.$router.push("/dashboard");
                                });
                            break;
                        case "manager":
                            admin = "manager";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-user-name", resp.data[0].full_name);
                                    localStorage.setItem(
                                        "hot-user-number",
                                        resp.data[0].phone_num
                                    );
                                    localStorage.setItem(
                                        "hot-user-2fa",
                                        resp.data[0].is_2f_authenticated
                                    );
                                    this.$router.push("/dashboard");
                                });
                            break;
                        case "employee":
                            admin = "employee";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "employees/user_data",
                                    {
                                        user_name: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-user-name", resp.data[0].full_name);
                                    localStorage.setItem(
                                        "hot-user-number",
                                        resp.data[0].phone_num
                                    );
                                    localStorage.setItem(
                                        "hot-user-2fa",
                                        resp.data[0].is_2f_authenticated
                                    );
                                    if (resp.data[0].employee_status == 0) {
                                        this.errors.invalid = "Account is Deactivated by Admin..!!";
                                    } else {
                                        this.$router.push("/dashboard");
                                    }
                                });
                            break;
                        case "sub-admin":
                            admin = "sub_admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    this.$router.push("/dashboard");
                                });

                            break;
                        default:
                            //this.$router.push("/login");
                            this.errors.invalid = "Not Valid..!!";
                    }
                })
                .catch(function (error) {
                    let errorText = "Something went wrong! Please try again later.";
                    if (error.response && error.response.status === 422) {
                        errorText = error.response.data.message;
                    }
                    self.processing = false;
                    Swal.fire({
                        title: "Error!",
                        html: errorText,
                        icon: "error",
                    });
                })
                .finally(() => (this.loading = false));
        },
    },
}
</script>
<style scoped src="@/snapp-associates/partials/master.css"></style>
<style scoped src="@/snapp-associates/partials/responsive.css"></style>